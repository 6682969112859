import React from "react";
import vid1 from "../../images/vid1.mov";
import vid2 from "../../images/vid2.mov";

const about = {
  title: "O mne",
  text: "Význam slova Rich Barber v krátkosti znamená precíznosť, kvalitná práca, spoľahlivosť a spokojnosť zákazníkov za najlepšie ceny v okolí. Veľké množstvo ľudí v okolí ma veľmi dobre skúsenosti overené dlhoročnou praxou a skúsenosťami. Pre mňa slovo barber znamená nie len hobby alebo remeslo, ale prináša to oveľa viac, ako sa dá čakať napríklad spoznavanie nových ľudí životný štýl, budovanie kariéry a zlepšovanie sa v remesle a naberanie nových módnych trendov a neustále zlepšovanie sa Jeden z mojich hlanych cieľov je vybudovať barbershop na kvalitnej úrovni, kde ľudia môžu očakávať oveľa viac ako niekde inde a rozšírenie prevádzky v meste",
};

const tombola = {
  title: "Vianočná tombola",
  text: "Ahojte 💈RichBarber💈 si pre vás pripravil vianočnú tombolu pre zákazníkov, ktorá bude úplne ZADARMO v Barbershope od 21 do 23 Decembra. Taktiež sa bude podávať PUNČ na účet podniku ako poďakovanie za dlho ročnú klientelu a taktiež aj novodobú klientelu, ku ktorej ste mi všetci dopomohli. Tieto Vianoce budú naše prvé Vianoce v novom Barbershope tak, preto by som chcel potešiť a poďakovať sa úplne všetkým týmto gestom, lebo spolu sme to dokázali. Veľmi pekne vám všetkým Ďakujem",
};

const openingHours = {
  title: "Otváracie hodiny",
  days: [
    {
      title: "Pondelok",
      hours: "8:30 - 20:00",
    },
    {
      title: "Utorok",
      hours: "8:30 - 20:00",
    },
    {
      title: "Streda",
      hours: "8:30 - 20:00",
    },
    {
      title: "Štvrtok",
      hours: "8:30 - 20:00",
    },
    {
      title: "Piatok",
      hours: "8:30 - 20:00",
    },
    {
      title: "Sobota",
      hours: "8:30 - 20:00",
    },
    {
      title: "Nedeľa",
      hours: "Zatvorené",
    },
  ],
};

export default function AboutMe() {
  return (
    <div className="flex flex-col gap-7 items-center">
      <div className="bg-black/75 p-4 backdrop-blur-sm max-w-[960px]">
        <div className="border border-primary p-8 flex flex-col gap-2 h-full">
          <video
            className="!object-fill"
            src={vid1}
            width="auto"
            height="auto"
            controls={false}
            autoPlay={true}
            muted
            loop
          />
        </div>
      </div>
      <div className="flex flex-col gap-8 md:flex-row md:items-start flex-wrap justify-center">
        <div className="bg-black/75 p-4 backdrop-blur-sm flex-1">
          <div className="border border-primary p-8 flex flex-col gap-2 h-full">
            <div className="text-primary font-bold text-2xl">{about.title}</div>
            <div className="text-white/90">{about.text}</div>
          </div>
        </div>
        {/* <div className="bg-black/75 p-4 backdrop-blur-sm flex-1">
          <div className="border border-primary p-8 flex flex-col gap-2 h-full">
            <div className="text-primary font-bold text-2xl">
              {tombola.title}
            </div>
            <div className="text-white/90">{tombola.text}</div>
          </div>
        </div> */}
        <div className="bg-black/75 p-4 backdrop-blur-sm flex-1">
          <div className="border border-primary p-8 flex flex-col gap-2 h-full">
            <div className="text-primary font-bold text-2xl">
              {openingHours.title}
            </div>
            <div className="text-white/90">
              {openingHours.days.map((day, index) => (
                <div className="flex justify-between" key={index}>
                  <div>{day.title}</div>
                  <div>{day.hours}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
