import React from "react";
import barber from "../../images/barber2.jpg";

export default function Prices() {
  const priceList = {
    title: "Cenník",
    prices: [
      {
        title: "-Pánsky strih",
        price: "10 €",
      },
      {
        title: "Junior strih",
        price: "8 €",
      },
      {
        title: "-Rich Combo balíček",
        price: "20 €",
        description:
          "(umytie vlasov, masáž, strih, holenie, prípravok po holení a styling)",
      },
      {
        title: "-VIP balíček",
        price: "20 €",
        description:
          "(strihanie a úprava kontúr strojčekom alebo britvou, pletová  maska na tvár, úprava obočia, naparovanie tváre, umytie vlasov)",
      },
      {
        title: "-Úprava brady",
        price: "5 €",
      },
      {
        title: "-Farbenie brady",
        price: "5 €",
      },
      {
        title: "-Farbenie vlasov od",
        price: "5 €",
      },
      {
        title: "-Čierna maska",
        price: "3 €",
      },
      {
        title: "-Umytie vlasov",
        price: "3 €",
      },
      {
        title: "-Wax depilacia",
        price: "5 €",
      },
    ],
  };

  return (
    <>
      <div className="flex flex-row md:flex-col gap-7 items-center">
        <div className="flex flex-col gap-8 md:flex-row md:items-start flex-wrap justify-center">
          <div className="bg-black/75 p-4 backdrop-blur-sm flex-1">
            <div className="border border-primary p-8 flex flex-col gap-2 h-full">
              <div className="text-primary font-bold text-2xl">
                {priceList.title}
              </div>
              <div className="text-white/90">
                {priceList.prices.map((item, index) => (
                  <div className="flex justify-between" key={index}>
                    <div>{item.title}</div>
                    <div>{item.price}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bg-black/75 p-4 backdrop-blur-sm flex-1">
            <div className="border border-primary p-8 flex flex-col gap-2 h-full items-center text-center">
              <img
                src="RB-poukazka.png"
                alt="Rich Barber"
                className="aspect-auto w-full sm:w-2/3 dog"
              />
              <p className=" text-white">
                Objavte umenie starostlivosti o bradu a vlasy! Darujte zážitok
                plný štýlu s naším dárčekovým poukazom na profesionálny strih,
                úpravu brady a relaxačné procedúry. Ideálny darček pre moderného
                muža, ktorý si rád dopraje trochu luxusu. Váš RichBarber – kde
                každý strih je majstrovské dielo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
