import React from "react";
import barber from "../../images/barber2.jpg";
import Map from "../Map";

export default function Contact() {
  const contact = {
    title: "Kontakt",
    contacts: [
      {
        title: "Telefón:",
        value: "0944 808 795",
      },
      {
        title: "Adresa:",
        value: "Vŕbnica 58, 072 16 ",
      },
      {
        title: "Email:",
        value: "richardgadzo56@gmail.com",
      },
    ],
  };

  return (
    <div className="flex flex-row md:flex-col gap-7 items-center">
      <div className="flex flex-col gap-8 md:flex-row md:items-start flex-wrap justify-center">
        <div className="bg-black/75 p-4 backdrop-blur-sm flex-1 md:min-w-[400px]">
          <div className="border border-primary p-8 flex flex-col gap-2 h-full">
            <div className="text-primary font-bold text-2xl">
              {contact.title}
            </div>
            <div className="text-white/90">
              {contact.contacts.map((contact, index) => (
                <div className="flex justify-between" key={index}>
                  <div>{contact.title}</div>
                  <div>{contact.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-black/75  backdrop-blur-sm flex-1 md:min-w-[900px] min-h-[600px] overflow-hidden">
          <Map />
        </div>
      </div>
    </div>
  );
}
