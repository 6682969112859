import { useState } from "react";
import Cookies from "universal-cookie";

export default function CookiesMessage() {
  const cookies = new Cookies();
  // eslint-disable-next-line
  const [accepted, setAccepted] = useState(false);

  function handleCookies() {
    cookies.set("accepted", { path: "/" });
    setAccepted(true);
  }
  //bg-[#6d653a]
  return (
    <>
      {cookies.get("accepted") !== undefined ? null : (
        <div className="fixed bottom-1 w-screen flex justify-center z-50">
          <div className="bg-black w-11/12 p-2 rounded-lg grid lg:grid-cols-5 border-[#6d653a] border-2">
            <div className="lg:col-start-1 lg:col-span-4 text-[#6d653a] flex justify-center items-center">
              <p>
                Táto stránka používa súbory cookies, aby vám ponúkla lepší
                zážitok z prehliadania. Prehliadaním tejto webovej stránky
                súhlasíte s naším používaním cookies.{" "}
              </p>
            </div>
            <div className="flex flex-row gap-2 justify-center items-center pt-2 lg:pt-0 lg:col-start-5">
              <a
                href="/cookie"
                target="_blank"
                className="uppercase border-2 border-[#6d653a] p-1 w-24 flex justify-center text-[#6d653a] hover:bg-[#6d653a] hover:text-black truncate"
              >
                Viac info.
              </a>
              <button
                className="uppercase border-2 border-[#6d653a] p-1 w-24 flex justify-center text-[#6d653a] hover:bg-[#6d653a] hover:text-black "
                onClick={handleCookies}
              >
                Súhlasím
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
