import { React, useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import pinsolid from "../images/pinsolid.svg";

export default function Map() {
  const [center, setCenter] = useState({
    lat: 48.67952710996952 - 0.0005,
    lng: 21.882859029910744 + 0.0008,
  });
  const view = "roadmap";
  const [libraries] = useState(["places"]);
  const [map, setMap] = useState(null);
  const containerStyle = { width: "100vw", height: "100vh" };

  const defaultMapOptions = {
    fullscreenControl: false,
    mapTypeId: view,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries,
  });

  const onLoad = useCallback(
    function callback(map) {
      setCenter({
        ...center,
        lat: 48.67952710996952,
        lng: 48.67952710996952,
      });
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setMap(map);
      map.setOptions({
        maxZoom: 18,
        minZoom: 8,
        center: center,
      });
    },
    [center, setMap, setCenter]
  );

  const onUnmount = useCallback(
    function callback(map) {
      setMap(null);
    },
    [setMap]
  );

  return isLoaded ? (
    <div className="absolute">
      <GoogleMap
        className="absolute z-0"
        id="mapa"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultMapOptions}
      >
        <Marker>
          <Marker
            position={{ lat: 48.67952710996952, lng: 21.882859029910744 }}
            label={{
              text: "RichBarber",
              color: "#000",
              fontSize: "24px",
              fontFamily: "Luckiest Guy",
              className: "marker-label",
            }}
            icon={{
              url: pinsolid,
              scaledSize: new window.google.maps.Size(70, 70),
            }}
          ></Marker>
        </Marker>
      </GoogleMap>
    </div>
  ) : null;
}
