import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const MobileNavbar = ({ navItems }) => {
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  return (
    <div className="container md:hidden items-center h-12 flex justify-end">
      <button
        className={`cursor-pointer absolute z-30 text-xl text-white leading-none px-3 py-1 flex justify-end `}
        type="button"
        onClick={toggleNavbar}
      >
        {isNavbarOpen ? (
          <FontAwesomeIcon icon="xmark" />
        ) : (
          <FontAwesomeIcon icon="bars" />
        )}
      </button>
      <a
        className="z-10 flex-1 rounded-lg relative top-5 sm:top-8 w-1/2 flex justify-start "
        href="/"
      >
        <img
          src="RB-logo.png"
          alt="Rich Barber"
          className="aspect-auto w-24 sm:w-32 dog"
        />
      </a>
      {isNavbarOpen && (
        <div className="z-10 bg-black flex gap-4 flex-col py-8 items-center fixed top-0 left-0 right-0">
          {navItems.map((navItem, index) => (
            <a
              key={index}
              className="text-[#6d653a] hover:text-black flex gap-2 items-center uppercase"
              href={navItem.path}
            >
              <FontAwesomeIcon icon={navItem.icon} />
              {navItem.title}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
