export default function Cookies() {
  return (
    <div className="flex flex-col gap-7 items-center">
      <div className="flex flex-col gap-8 md:flex-row md:items-start flex-wrap justify-center">
        <div className="bg-black/75 p-4 backdrop-blur-sm flex-1">
          <div className="border border-primary text-primary p-8 flex flex-col gap-2 h-full">
            <h1 className="text-xl underline font-bold">
              Ochrana osobných údajov
            </h1>{" "}
            Tento dokument stanovujúci zásady ochrany osobných údajov popisuje
            postupy prijaté spoločnosťou RichBarber, Vŕbnica 58, Vrbnica, 072 16
            Hatalov (ďalej len RichBarber) ohľadom spracovania osobných údajov
            užívateľov. Ide o všeobecnú informáciu adresovanú tomu, kto navštívi
            tieto webové stránky a to v zmysle zákona č. 122/2013 o ochrane
            osobných údajov v znení zákona č. 84/2014 Z. z. (ďalej len „zákon o
            ochrane osobných údajov“ ). Pri využívaní niektorých služieb
            ponúkaných a poskytovaných prostredníctvom tejto webovej stránky
            užívatelia poskytnú určité informácie, budú vznášať určité
            požiadavky a poskytnú súhlas so spracovaním svojich osobných údajov,
            ak je to zo zákona vyžadované.{" "}
            <h2 className="text-lg underline font-bold">Spracovanie údajov</h2>{" "}
            Výsledkom prístupu k týmto webovým stránkam môže byť spracovanie
            údajov týkajúcich sa určených alebo určiteľných osôb. Správcom
            údajov je RichBarber, ktorá zaistí primerané organizačné a technické
            spracovanie údajov za účelom zabezpečenia týchto údajov proti
            neoprávnenému prístupu, strate, poškodeniu, krádeži , použitiu alebo
            inému zverejneniu údajov.{" "}
            <h2 className="text-lg underline font-bold">
              Miesto spracovania údajov{" "}
            </h2>
            Osobné údaje budú spracované v priestoroch spoločností RichBarber. S
            údajmi budú pracovať osoby s príslušnými technickými zručnosťami a
            schopnosťami, ktorým boli pridelené špecifické funkcie v oblasti
            spracovania údajov.{" "}
            <h2 className="text-lg underline font-bold">
              Postupy pri spracovaní údajov
            </h2>{" "}
            Údaje budú spracované korektným spôsobom a plne v súlade so zákonom
            tak, aby sa zabezpečila ochrana údajov a ich dôverný charakter.
            Údaje budú spracované elektronickými alebo automatizovanými
            prostriedkami.
            <h2 className="text-lg underline font-bold">
              Typy spracúvaných údajov
            </h2>{" "}
            Informačné systémy a softvérové ​​postupy použité pri prevádzkovaní
            týchto webových stránok získavajú v priebehu svojho bežnej prevádzky
            určité osobné údaje. Prenos týchto údajov je implicitne daný
            využitím ICP protokolov. Údaje nie sú zhromažďované s cieľom spájať
            ich s konkrétnymi subjektmi, avšak už vďaka svojmu charakteru
            umožňujú identifikovať užívateľa prostredníctvom ich spracovania a
            pridruženia k iným údajom držaným tretími osobami. Táto kategória
            údajov obsahuje IP adresy a doménové mená počítačov, ktoré používajú
            užívatelia pripájajúce sa k sieti, ďalej potom URI adresy
            požadovaných zdrojov, časové určenie požiadaviek, použitý postup pri
            vznášaní požiadaviek a ďalšie parametre vzťahujúce sa k operačnému
            systému a informačnému prostrediu používateľa. Dobrovoľné zaslanie
            emailovej adresy používateľa na adresu na webovej stránke má za
            následok získanie osobných údajov používateľa potrebných na to, aby
            bolo možné reagovať na požiadavky tohto používateľa.{" "}
            <h2 className="text-lg underline font-bold">Poskytovanie údajov</h2>{" "}
            Je na užívateľoch, či poskytnú osobné údaje, ktoré spoločnosť
            RichBarber potrebuje na to, aby mohla poskytnúť požadované služby.
            Neposkytnutie týchto údajov môže vyššie uvedenej spoločnosti
            znemožniť dodanie požadovaných informácií alebo služieb. Cookies sa
            nepoužívajú na prenos osobných informácií. Užitie cookies relácií,
            ktoré nie sú trvalo ukladané v počítači užívateľa a ktoré po vypnutí
            prehliadača zmiznú, je striktne obmedzené na prenos identifikačných
            kódov relácie, ktoré obsahujú náhodné čísla generované serverom,
            potrebné k bezpečnej navigáciu na webovej stránke. Používanie týchto
            cookies relácií tak znemožňuje použitiu iných informačných
            technológií, ktoré by mohli predstavovať potenciálne ohrozenie
            dôverného charakteru navigácie. Neumožňujú získanie osobných
            informácií, s ktorých pomocou by bolo možné identifikovať
            používateľa.{" "}
            <h2 className="text-lg underline font-bold">Cookies</h2>{" "}
            <h2 className="text-lg underline font-bold">Práva užívateľa</h2> V
            súlade so zákonom o ochrane osobných údajov možno osobné údaje
            spracovávať len po dobu, ktorá je nevyhnutná na účel ich
            spracovania. Podľa zákona o ochrane osobných údajov majú
            používatelia – subjekty údajov – právo, ak zistia, alebo ak sa
            domnievajú, že správca alebo spracovateľ vykonáva spracovanie ich
            osobných údajov, ktoré je v rozpore s ochranou ich súkromného a
            osobného života, alebo v rozpore so zákonom, najmä ak sú osobné
            údaje nepresné s ohľadom na účel ich spracovania, požiadať správcu
            alebo spracovateľa o vysvetlenie požadovať, aby správca alebo
            spracovateľ odstránil vzniknutý stav, hlavne údaje blokoval, vykonal
            opravy, doplnenie alebo ich zlikvidoval. Žiadosť podľa
            predchádzajúceho odseku je potrebné predložiť spoločnosti
            RichBarber, Vŕbnica 58, Vrbnica, 072 16 Hatalov{" "}
            <h2 className="text-lg underline font-bold">Obchodné oznámenie</h2>{" "}
            V súlade so zákonom o ochrane osobných údajov je RichBarber ako
            prevádzkovateľ webovej stránky richbarber.sk, oprávnený použiť
            telefónne číslo alebo e-mailovú adresu poskytnuté užívateľom pre
            potreby šírenia obchodných oznámení týkajúce sa produktov a služieb
            RichBarber s tým, že užívateľ je kedykoľvek oprávnený odmietnuť
            súhlas so zasielaním obchodných oznámení či s poskytovaním takýchto
            informácií prostredníctvom telefonického kontaktu , a to listinou
            formou doručením do sídla spoločnosti RichBarber alebo zaslaním
            emailu na adresu richardgadzo56@gmail.com. Odmietnutie súhlasu so
            šírením obchodných oznámení nie je nijako spoplatnené.
          </div>
        </div>
      </div>
    </div>
  );
}
