import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";

export const DesktopNavbar = ({ navItems }) => {
  return (
    <div className="hidden relative z-20 bg-black/75 backdrop-blur-sm md:block border-b border-[#6d653a]">
      <div className="container flex h-16 items-center">
        {navItems.map((navItem, index) => (
          <Fragment key={index}>
            <a
              className="flex-1 h-10 items-center rounded-lg text-[#6d653a] hover:text-white hover:bg-[#6d653a] text-sm w-full font-bold leading-relaxed flex justify-center whitespace-nowrap uppercase"
              href={navItem.path}
            >
              <span className="font-sans text-xl ">
                <FontAwesomeIcon icon={navItem.icon} /> {navItem.title}
              </span>
            </a>
            {index === 1 && (
              <a
                className="z-20 flex-1 rounded-lg relative top-5 sm:top-8 w-full flex justify-center"
                href="/"
              >
                <img
                  src="RB-logo.png"
                  alt="Rich Barber"
                  className="aspect-auto w-24 sm:w-32 dog"
                />
              </a>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
