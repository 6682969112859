import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Carousel } from "react-responsive-carousel";
import { faX } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import strih1 from "../../images/strih1.jpg";
import strih2 from "../../images/strih2.jpg";
import strih3 from "../../images/strih3.jpg";
import strih4 from "../../images/strih4.jpg";
import strih5 from "../../images/strih5.jpg";
import strih6 from "../../images/strih6.jpg";
import strih7 from "../../images/strih7.jpg";
import strih8 from "../../images/strih8.jpg";
import strih9 from "../../images/strih9.jpg";
import strih10 from "../../images/strih10.jpg";
import strih11 from "../../images/strih11.jpg";
import strih12 from "../../images/strih12.jpg";
import room1 from "../../images/room1.jpg";
import room2 from "../../images/room2.jpg";
// import outside1 from "../../images/outside1.jpg";
// import outside2 from "../../images/outside2.jpg";
// import outside3 from "../../images/outside3.jpg";
import products from "../../images/products.jpg";

export default function Gallery() {
  library.add(faX);

  const [isCarousel, setIsCarousel] = useState(false);

  const settings = {
    infiniteLoop: true,
    slide: true,
  };

  return (
    <>
      <div className="min-h-screen h-96 bg-cover flex justify-center overflow-scroll">
        {isCarousel ? (
          <div className="w-[330px] h-10 sm:w-[470px] relative ">
            <button
              onClick={() => setIsCarousel(false)}
              className="absolute right-0 sm:-right-10 bg-[#6d653a] w-10 h-10 z-10 flex justify-center items-center"
            >
              <FontAwesomeIcon icon="x" className="text-xl w-8" />
            </button>
            <Carousel {...settings}>
              <img src={strih1} alt="..." />
              <img src={strih2} alt="..." />
              <img src={strih3} alt="..." />
              <img src={strih4} alt="..." />
              <img src={strih5} alt="..." />
              <img src={strih6} alt="..." />
              <img src={strih7} alt="..." />
              <img src={strih8} alt="..." />
              <img src={strih9} alt="..." />
              <img src={strih10} alt="..." />
              <img src={strih11} alt="..." />
              <img src={strih12} alt="..." />
              <img src={room1} alt="..." />
              <img src={room2} alt="..." />
              <img src={products} alt="..." />
            </Carousel>
          </div>
        ) : (
          <div className="row">
            <div className="column">
              <img
                onClick={() => setIsCarousel(true)}
                src={strih1}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih2}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih3}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={room1}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
            </div>
            <div className="column">
              <img
                onClick={() => setIsCarousel(true)}
                src={strih4}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih5}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih6}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={products}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
            </div>
            <div className="column">
              <img
                onClick={() => setIsCarousel(true)}
                src={strih7}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih8}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={room2}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih9}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
            </div>
            <div className="column">
              <img
                onClick={() => setIsCarousel(true)}
                src={strih10}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih11}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
              <img
                onClick={() => setIsCarousel(true)}
                src={strih12}
                style={{ width: "100%" }}
                alt="img"
                className="hover:scale-105"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
