import React, { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faXmark,
  faAddressCard,
  faPhone,
  faTag,
  faAddressBook,
  faImages,
} from "@fortawesome/free-solid-svg-icons";
import { DesktopNavbar } from "./DesktopNavbar";
import { MobileNavbar } from "./MobileNavbar";

const navItems = [
  {
    title: "O mne",
    icon: "address-card",
    path: "/",
  },
  {
    title: "Cenník",
    icon: "tag",
    path: "/cennik",
  },
  {
    title: "Kontakt",
    icon: "address-book",
    path: "/kontakt",
  },
  {
    title: "Galéria",
    icon: "images",
    path: "/galeria",
  },
];

export const Navbar = () => {
  library.add(faBars);
  library.add(faXmark);
  library.add(faPhone);
  library.add(faAddressCard);
  library.add(faTag);
  library.add(faAddressBook);
  library.add(faImages);

  return (
    <>
      <DesktopNavbar navItems={navItems} />
      <MobileNavbar navItems={navItems} />
    </>
  );
};
