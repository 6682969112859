import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "./components/layout/Layout";

import AboutMe from "./components/routes/AboutMe";
import Prices from "./components/routes/Prices";
import Contact from "./components/routes/Contact";
//import Footer from "./components/routes/FooterComponent";
import Gallery from "./components/routes/Gallery";
import Cookies from "./components/routes/Cookies";
import CookiesMessage from "./components/CookiesMessage";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<AboutMe />} />
          <Route path="/cennik" element={<Prices />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/galeria" element={<Gallery />} />
          <Route path="/cookie" element={<Cookies />} />
        </Routes>
      </Layout>
      {/*<Footer />*/}
      <CookiesMessage />
    </Router>
  );
}
//<Route path="*" element={<NoPage />} />;
export default App;
