import barber from "../../images/barber2.jpg";
import { Navbar } from "./navbar/Navbar";

export const Layout = ({ children }) => {
  return (
    <div
      style={{ backgroundImage: `url(${barber})` }}
      className="bg-cover min-h-full"
    >
      <Navbar />

      <div className="container py-8 md:py-12">{children}</div>
    </div>
  );
};
